import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import { graphql, PageProps } from "gatsby"
import _ from "lodash"
import React from "react"
import Scroll from "react-scroll"

import { appBarHeight } from "../components/Header"
import { Layout } from "../components/Layout"
import { Link } from "../components/Link"
import { PageContext } from "../interfaces/pageContext"

const SECTIONS = ["0-9"].concat("abcdefghijklmnopqrstuvwxyz".toUpperCase().split(""))

const useStyles = makeStyles((theme) => ({
  heading: {
    textAlign: "center",
  },
  container: {
    "& hr": {
      borderBottom: "1px solid #ededed",
    },
  },
  searchResults: {
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textAlign: "center",
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
  disabled: {
    color: theme.palette.grey[400],
  },
  sections: {
    listStyle: "none",
    textAlign: "center",
    padding: 0,
    margin: 0,

    "& li": {
      display: "inline-block",
      cursor: "pointer",

      "& a, & span": {
        fontSize: "1rem",
        lineHeight: "3rem",
        padding: "0 1rem",
      },
    },
  },
  sameLetterBrands: {
    listStyle: "none",
  },
  letterBox: {},
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}))

export type BrandIndexPageProps = PageProps<any, PageContext>

const BrandIndexPage: React.FC<BrandIndexPageProps> = (props) => {
  const classes = useStyles(props)
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const { data, location, pageContext } = props
  const brandsByLetter = _.groupBy(data.brandPages.edges, ({ node }) => {
    const letter = node.context.brand[0].toUpperCase()
    return "0123456789".includes(letter) ? "0-9" : letter
  })
  pageContext.title = "Alle Designer & Marken"
  return (
    <Layout location={location} pageContext={pageContext}>
      <Grid container={true} spacing={2} alignItems="stretch" direction="column">
        <Grid item={true} className={classes.heading}>
          <div>
            <Typography className={classes.inline} variant="h1">
              Alle Designer & Marken
            </Typography>
            <Typography component="span" variant="h1">
              {" "}
              ({data.brandPages.totalCount})
            </Typography>
          </div>
        </Grid>
        <Grid item={true}>
          <Typography paragraph={true} align="center">
            Hier findest du eine Übersicht aller Designer & Marken deren Taschen du in unseren
            Partnershops kaufen kannst.
          </Typography>
        </Grid>

        <Grid item={true}>
          <ul className={classes.sections}>
            {SECTIONS.map((section, index) => (
              <li key={index}>
                {Object.keys(brandsByLetter).includes(section) ? (
                  <Scroll.Link
                    to={section}
                    smooth={true}
                    offset={-(isSmUp ? appBarHeight.sm : appBarHeight.xs) - theme.spacing(1)}
                  >
                    {section}
                  </Scroll.Link>
                ) : (
                  <span className={classes.disabled}>{section}</span>
                )}
              </li>
            ))}
          </ul>
          <List className={classes.root}>
            {Object.keys(brandsByLetter).map((letter) => (
              <React.Fragment key={letter}>
                <Divider component="li" variant="middle" light={true} />
                <ListItem alignItems="flex-start" className={classes.listItem}>
                  <ListItemAvatar>
                    <Typography variant="h2" component="span" id={letter}>
                      {letter}
                    </Typography>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Grid
                        container={true}
                        spacing={1}
                        component="ul"
                        className={classes.sameLetterBrands}
                      >
                        {_.sortBy(brandsByLetter[letter], [
                          ({ node }): string => node.context.brand.toLowerCase(),
                        ]).map(({ node }, index) => (
                          <Grid item={true} xs={12} sm={6} md={4} lg={3} component="li" key={index}>
                            <Link href={node.path}>{node.context.brand}</Link>
                          </Grid>
                        ))}
                      </Grid>
                    }
                  />
                </ListItem>
              </React.Fragment>
            ))}
          </List>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default BrandIndexPage

export const query = graphql`
  query BrandIndexPageQuery {
    brandPages: allSitePage(
      filter: { context: { type: { eq: "BrandPage" }, humanPageNumber: { eq: 1 } } }
      sort: { fields: [context___brand], order: ASC }
    ) {
      edges {
        node {
          path
          context {
            brand
          }
        }
      }
      totalCount
    }
  }
`
